import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { getUsersAnalytics } from "services";
import { getAverageScore } from "utils";
import StaticTable from "components/Tables/StaticTable";
import { Box } from "@mui/material";
import { IconButton } from "components/CustomButtons";
import { useTheme } from "@mui/styles";

const commonProperties = {
  type: "number",
  renderCell: ({ row, field }) =>
    row.finishedAttempts === 0 ? "-" : Math.round(row[field]) || 0,
  align: "center",
};

const columns = [
  { field: "name", label: "Name", type: "string", minWidth: 120, flex: 1, fixed: 1 },
  {
    field: "overallScore",
    type: "number",
    ...commonProperties,
    align: "center",
    label: "Overall Score",
    minWidth: 80,
    sort: true,
  },
  {
    field: "contentOverall",
    label: "Content",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "communicationOverall",
    label: "Speech",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "finishedAttempts",
    label: "Finished Attempts",
    ...commonProperties,
    minWidth: 120,
  },
  {
    field: "unfinishedAttempts",
    label: "Unfinished Attempts",
    renderCell: ({ row, field }) => Math.round(row[field]) || "-",
    align: "center",
    type: "number",
    minWidth: 120,
  },
  {
    field: "communicationConfidence",
    label: "Confidence",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "communicationDelivery",
    label: "Delivery",
    ...commonProperties,
    minWidth: 70,
  },
  {
    field: "communicationFluency",
    label: "Fluency",
    ...commonProperties,
    minWidth: 70,
  },
  {
    field: "communicationWord_Choice",
    label: "Word choice",
    ...commonProperties,
    minWidth: 100,
  },
  {
    field: "contentSimilarity",
    label: "Similarity",
    ...commonProperties,
    minWidth: 80,
  },
  {
    field: "contentRelevance",
    label: "Relevance",
    ...commonProperties,
    minWidth: 90,
  },
  {
    field: "contentLogic_Flow",
    label: "Logic flow",
    ...commonProperties,
    minWidth: 100,
  },
];

function UserAnalytics({ batch }) {
  const { id } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [rowSelectionModel, setRowSelectionModel] = React.useState([])

  const initialFilters = [
    "name", "overallScore", "contentOverall", "communicationOverall", "finishedAttempts",
    "unfinishedAttempts"
  ]

  React.useEffect(() => {
    setLoading(true);
    getUsersAnalytics(id)
      .then(setRows)
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [id]);

  React.useEffect(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      navigate(`/students/${rowSelectionModel[0]}`);
    }
  }, [rowSelectionModel]);

  const _rows = React.useMemo(() => {
    const allRows = rows?.map((rowData) => {
      return {
        ...rowData,
        overallScore: getAverageScore(rowData.communicationOverall, rowData.contentOverall)
      }
    })

    return allRows
  }, [rows])

  const getActions = [
    {
      title: "View Performance",
      renderIcon:(param)=> <IconButton 
                            variant='contained'
                            style={{
                                      border : `1px solid ${theme.palette.primary.main}`,
                                      padding : theme.spacing(1)
                                  }}
                            >
                              <img 
                                src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/PerformanceIcon.svg' 
                                alt='performance' 
                              />
                            </IconButton>,
      label: "View Performance",
      onClick: (param) => navigate(`/students/${param.id}`)
    }
  ]

  return (
    <Box>
      <br />
      <Divider light flex />
      <Box mt={2} mb={5}>
        <Typography variant="h4-medium">
          Users Performance
        </Typography>
      </Box>

      <StaticTable
        loading={loading}
        columns={columns}
        actions={getActions}
        rows={_rows}
        initialFilters={initialFilters}
        noRowsMessage="No students found in this batch!!"
        sortByColumns={
          [
            { ...columns[1], sortByTitle: "Scores: High to Low", sortBy: 'desc' },
            { ...columns[1], sortByTitle: "Scores: Low to High", sortBy: 'asc' },
            { ...columns[4], sortByTitle: "Engagement: High to Low", sortBy: 'desc' },
            { ...columns[4], sortByTitle: "Engagement: Low to High", sortBy: 'asc' }
          ]
        }
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
        useItemProps={{
          name: batch?.name,
          exportService: null,
          payload: null
        }}
      />
    </Box>
  );
}

export default UserAnalytics;
