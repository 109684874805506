import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography'
import Box from "@mui/material/Box";
import dayjs from 'dayjs';

import { useSnackbar, useTour } from 'contexts';
import { getBatches, getTemplates } from "services";
import { formatDate, Session } from "utils";
import CardList from 'components/CardList';
import OverallPerformance from 'components/Home/OverallPerformance';
import QuickHighlight from 'components/Home/QuickHighlight';
import ScreenHeading from 'components/ScreenHeading';
import CustomButton from 'components/CustomButtons';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles(theme=>({
  welcomeContainer:{
    display:'flex',
    justifyContent:'center',
    flexDirection:'column',
    gap:'24px',
    padding:theme.spacing(6,10),
    backgroundColor:theme.palette.shades['clr-white-900'],
    boxShadow: '0px 0px 4px 0px #00000026',
    borderRadius:theme.spacing(1),
    position:'relative',
    marginBottom:'20px'
  },
  circularPads:{
    position:'absolute',
    left:0,
    top:0,
    width:'186px',
    transform: 'rotate(270deg)',
  },
  emptyBatch:{
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    padding:theme.spacing(6,5),
    backgroundColor:theme.palette.warning['clr-100'],
    borderRadius:theme.spacing(2),
    zIndex:2
  },
  focusContainer:{
    display:'flex',
    gap:theme.spacing(5),
    alignItems:'center'
  },
  contentBatch:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(2)
  },
  fire:{
    position:'relative',
    top:'4px'
  }
}))

const getTemplateInfos = (data) => [
  <>
    <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/graduation-cap-black.svg" alt="batch" />&nbsp;
    <Typography variant='body01-semiBold' color='neutral.clr-700'>{data?.batch?.name || 'NA'}</Typography>
  </>,
  <Box sx={{marginTop:'8px' , paddingTop:'8px' , borderTop:'1px solid #CCD4DE' , width:'405px'}}>
    <Typography variant='body01-semiBold' color='#4E5661'>
      Published on:&nbsp;{formatDate(data.publishOn, "Do MMM YY")}
      &nbsp;&nbsp;|&nbsp;&nbsp;Expiry:&nbsp; {
        (dayjs(data.expiresOn).year() === 9999) ?
          'Never' :
          formatDate(data.expiresOn, "Do MMM YY")}
    </Typography>
  </Box>,
];

const CreateBatch = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box className={classes.emptyBatch}>
      <Box className={classes.focusContainer}>
        <img 
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/gifs/dart.gif' 
          alt='dart'
          height={72}
          width={72}
        />
        <Box className={classes.contentBatch}>
          <Typography variant='h5-semiBold' color='neutral.clr-800'>
            Let’s focus on improving students’ learning outcome
          </Typography>
          <Typography variant='body01-bold' color='neutral.clr-500'>
            It’s time to create a batch and assess the performance of students
            <img 
              src='https://languify-assets.s3.ap-south-1.amazonaws.com/fire.gif' 
              alt='fire' 
              width={20}
              height={20}
              className={classes.fire}
            />
          </Typography>
        </Box>
      </Box>
      <CustomButton
        variant='contained'
        size='medium'
        onClick={()=> navigate('/batches/create')}
      >
        Create new batch
      </CustomButton>
    </Box>
  )
}

function Home(props) {
  const { show } = useTour();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();

  const [templates, setTemplates] = useState([]);
  const [batches, setBatches] = useState([]);
  const [loadingBatches, setLoadingBatches] = useState(true); 

  useEffect(() => {
    (async () => {
      setLoadingBatches(true); 
      try {
        const { batches } = await getBatches({
          client: Session.userId, orderBy: "createdAt", order: "desc",
          pageSize: 5,
        });

        batches.forEach(batch => {
          batch.tags = [];
          batch.onClick = () => navigate("/batches/" + batch._id);
          batch.infos = [
            <>
              <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/student_icon.svg" alt="student" />&nbsp;
              {batch?.totalStudentCount} Students&nbsp;&nbsp;&nbsp;&nbsp;
            </>,
            <>
              <img src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/AssessmentIcon.svg' alt='assessment' />&nbsp;
              {batch?.totalTemplateCount} Assessments
            </>,
            <Box sx={{marginTop:'8px' , paddingTop:'8px' , borderTop:'1px solid #CCD4DE' , width:'374px'}}>
              <Typography variant='body01-semiBold' color='neutral.clr-600'> 
                Created on: {formatDate(batch?.createdAt, "Do MMM YY")}
              </Typography>
            </Box>,
          ];
        });
        setBatches(batches);
      } catch (error) {
        console.error(error);
        snackbar.showSnackbar("No batches found", "error");
      } finally {
        setLoadingBatches(false); 
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { interviewTemplates } = await getTemplates({
          user: Session.userId, orderBy: "createdAt", order: "desc",
          pageSize: 5, include: "batch"
        });

        const modifiedTemplates = [];
        interviewTemplates.forEach((it) => {
          if (it.type === 'follow_up') return;

          const tags = [];
          if (it.expiresOn && dayjs().isAfter(it.expiresOn)) {
            tags.push({ name: `Expired`, expire: true });
          } else if (it.publishOn && dayjs().isAfter(it.publishOn)) {
            tags.push({ name: "Live", expire: false });
          }

          modifiedTemplates.push({
            onClick: () => navigate("/assessments/" + it._id),
            name: it.name, type: it.type, tags,
            infos: getTemplateInfos(it),
          });
        });

        setTemplates(modifiedTemplates);
      } catch (error) {
        console.error(error);
        snackbar.showSnackbar("No templates found", "error");
      } finally {
      }
    })();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("tour-visited")) {
      localStorage.setItem("tour-visited", true);
      show();
    }
  }, []);

  return (
    <>
      <Box  marginBottom={"0.75rem"}>
        <ScreenHeading title="Dashboard" />
      </Box>
      <Box className={classes.welcomeContainer}>
        <img 
          src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/circularPads.svg' 
          alt='pads'
          className={classes.circularPads}
        />
        <Typography variant='h3-medium' color='primary.clr-500' sx={{zIndex:2}}>
          Welcome Admin
        </Typography>
        {
          loadingBatches ? (
            <Skeleton variant="rectangular" width="100%" height={250} animation="wave" />
          ) : (
            batches.length ? <QuickHighlight /> : <CreateBatch />
          )
        }
      </Box>
      
      <OverallPerformance />
      <CardList
        showMore
        items={batches}
        title="Batches"
        subTitle='List of all batches created'
        newPath={'/batches/create'}
        seeMorePath={`/batches`}
        cardTitle="Click to edit this batch"
      />
      <CardList
        showMore
        items={templates}
        title="Assessments"
        subTitle='All the assessments published in different batches'
        newPath={'/assessments/create'}
        seeMorePath={`/assessments`}
        cardTitle="Click to edit this assessment"
      />
      <br />
    </>
  );
}

export default Home;