import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import theme from "theme";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from 'dayjs';
import { makeStyles } from "@mui/styles";
import { IconButton, Paper } from "@mui/material";
import { IconButton as CustomIconButton } from "components/CustomButtons";
import { useNavigate } from "react-router-dom";

import DynamicTable from "components/DynamicTable";
import {
  getBatches,
  getDomains,
  getPersonalizedInterview,
} from "services";
import { BootstrapTooltip } from "components/InfoIconWithTooltip";
import { Session, copyToClipboard, formatDate, getClientPrefix } from 'utils';
import { applyFiltersByHistory } from "utils";
import useEditorStore from "editors/TemplateEditor/editorStore";
import { useSnackbar } from "contexts";
import { WhiteBox } from "components/AppShell";


const useStyle = makeStyles(theme => ({
  container: {
    borderRadius: theme.spacing(0.5),
    background: "#FFFFFF",
    overflow: "hidden",
    boxShadow: " 0px 0px 4px 0px #00000026",
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(3, 5),
    flexDirection: "column"
  },
  heading: {
    fontSize: "28px",
    fontWeight: 500,
    lineHeight: "35.84px",
    textAlign: "left"
  },
  h3: {
    fontSize: "19px",
    fontWeight: 500,
    lineHeight: "23.56px",
    textAlign: "left"
  },
  borderBottom: {
    borderBottom: "1px solid #8692A3",
    paddingBottom: theme.spacing(1.5),
    width: "100%"
  },
  chipText: {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: 0.7,
  },
}))

const Status = {
  live: { color: "#00664A", label: "Live", bgColor: '#DCFCE7' },
  expired: { color: "#C51407", label: "Expired", bgColor: '#FFE6E3' },
  unpublished: { color: "#BF7900", label: "Unpublished", bgColor: '#FFF6E5' },
};

const filters = [
  { field: "domain", type: "select", label: "Domain" },
  { field: 'batch', type: 'select', label: 'Batch' },
];



function StatusChip({ bgColor, color, label, tooltip }) {
  const classes = useStyle();
  

  return (
    <Box className={classes.chip} style={{ backgroundColor: bgColor || '#747775' , marginRight:'20px', 
      borderRadius:'16px' , padding:theme.spacing(0.25,2)
     }}>
      <BootstrapTooltip title={tooltip} >
        <Typography className={classes.chipText} color={color || 'black'}>
          {label || 'Label'}
        </Typography>
      </BootstrapTooltip>
    </Box>
  );
}

export default function PersonalizedInterviewList({refresh , setRefresh}) {
  const classes = useStyle();
  const navigate= useNavigate();
  const snackbar = useSnackbar();
  const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
  const [filterOptions, setFilterOptions] = React.useState({});

  const [initialFilters, setInitialFilters] = React.useState({
    user: Session.userId,
    includeTotal: true,
    type: "personalized",
    orderBy: "updatedAt",
    order: "desc",
  });

  const pageFilters = useEditorStore((state) => state.pageFilters)
  const setPageFilters = useEditorStore((state) => state.setPageFilters);
  const pageHistory = Session.pageHistory

  React.useEffect(() => {
    if (rowSelectionModel && rowSelectionModel.length > 0) {
      navigate(`${rowSelectionModel[0]}/performance`);
    }
  }, [rowSelectionModel]);

  React.useEffect(() => {
    applyFiltersByHistory(pageFilters, setPageFilters, setInitialFilters);
  }, [pageHistory])

  React.useEffect(() => {
    getDomains()
    .then(({ interviewDomains }) => {
      setFilterOptions((fO) => ({
        ...fO,
        domain: interviewDomains.map((d) => ({ id: d._id, label: d.name })),
      }));
    })
    .catch(console.error);

    getBatches({ client: Session.userId })
      .then(({ batches }) => {
        setFilterOptions((fO) => ({
          ...fO,
          batch: batches.map((b) => ({ id: b._id, label: b.name })),
        }));
      })
      .catch(console.error);
  }, []);

  const columns = [
    {
      field: 'name', label: 'Mock Inteview name', minWidth: 100,
      sortBy: "data.INTERVIEW_DETAILS.name"
    },
    {
      id: "status", field: "status", label: "Status",
      align: "center", minWidth: 130, marginRight:'10px',
      flex: 0, sortBy: "data.INTERVIEW_DETAILS.publishOn",
      renderCell: ({ row }) => {
        let status = Status.unpublished;
        let publishDate = row?.publishOn;
        let expireDate = row?.expiresOn;
        let tooltip = `Publising on ${formatDate(publishDate, 'DD/MM/YYYY')}`;
  
        if (publishDate && dayjs(publishDate).isBefore(new Date())) {
          status = Status.live;
          if (dayjs(expireDate).year() === 9999) {
            tooltip = `Never Expires`;
          }
          else {
            tooltip = `Expires on ${formatDate(expireDate, 'DD/MM/YYYY')}`;
          }
        }
  
        if (expireDate && dayjs(expireDate).isBefore(new Date())) {
          status = Status.expired;
          tooltip = `Expired on ${formatDate(expireDate, 'DD/MM/YYYY')}`;
        }
  
        return (
          <Box width='100%' display='flex' justifyContent='center'>
            <StatusChip
              color={status.color} bgColor={status.bgColor} label={status.label} tooltip={tooltip}
            />
          </Box>
        );
      },
    },
    {
      id: 'publishOn', field: 'publishOn', type: 'date',
      label: 'Published on', minWidth: 100, align: 'center',
      sortBy: "data.INTERVIEW_DETAILS.publishOn",
      renderCell: ({ row }) =>( 
        dayjs(row?.publishOn).isValid() ?
          formatDate(row?.publishOn, 'DD-MMM-YYYY') : '-'
      )
    },
    {
      id: 'attemptedBy', field: 'attemptedBy', type: '', disableSorting: true,
      label: 'Attempted by', minWidth: 100, align: 'center',
      renderCell: ({ row }) => {
        return row?.attemptedBy?.length || '0';
      }
    },
    {
      id: 'batch',
      label: 'Batch', minWidth: 100, align: 'center',
      renderCell: ({ row }) => {
        return row?.batch?.name || '0';
      }
    },
    {
      id: 'company', field: 'company',
      label: 'Company name', minWidth: 100, align: 'center',
      sortBy: "data.INTERVIEW_DETAILS.company",
    },
    {
      id: 'jobRole', field: 'jobRole',
      label: 'Job role', minWidth: 100, align: 'center',
      sortBy: "data.INTERVIEW_DETAILS.jobRole",
    },
    {
      id: 'link', field: 'link', disableSorting: true,
      label: 'Invite link', minWidth: 100, align: 'center',
      renderCell: ({ row }) =>
        <IconButton size="small" onClick={() => {
          const link = `${process.env.REACT_APP_INPREP_URL}/${getClientPrefix()}/home?pid=${row._id}`;
          copyToClipboard(link);
          snackbar.success('Copied');
        }} >
          <ContentCopyIcon fontSize="small" color="primary" />
        </IconButton>,
    },
    {
      id: 'domain', field: 'domain',
      sortBy: "data.INTERVIEW_DETAILS.domain",
      label: 'Domain', minWidth: 100, align: 'center',
      renderCell: ({ row }) => {
        return row?.domain?.name || '';
      }
    },
  ];

  return (
    <WhiteBox >
      <Box className={classes.borderBottom}>
        <Typography className={classes.heading}>
          Published mock interviews
        </Typography>
        <Typography className={classes.h3}>
          History of all the mock interviews published for the different batches
        </Typography>
      </Box>
      <Paper elevation={0} style={{ width: "100%" }}>
        <DynamicTable
          hideDeleteAction
          entity="Draft"
          noRowsMessage="You have not published any mock interview for any batch so far! Once 
          published you can see in this section"
          columns={columns}
          filters={filters}
          filterOptions={filterOptions}
          sx={{ p: 0 }}
          useListProps={{
            getItems: getPersonalizedInterview,
            itemName: 'templates',
            initialFilters: initialFilters
          }}
          rowSelectionModel={rowSelectionModel}
          setRowSelectionModel={setRowSelectionModel}
          personalizedList={true}
          refreshData={refresh}
          setRefresh={setRefresh}
          actions={[{
            label: "Analytics",
            renderIcon:(param)=> <CustomIconButton 
                                  variant='contained'
                                  style={{
                                            border : `1px solid ${theme.palette.primary.main}`,
                                            padding : theme.spacing(1)
                                        }}
                                  >
                                    <img 
                                      src='https://languify-assets.s3.ap-south-1.amazonaws.com/images/PerformanceIcon.svg' 
                                      alt='performance' 
                                    />
                                  </CustomIconButton>,
            onClick: (param) => navigate(`${param.id}/performance`)         
          },]}
        />
      </Paper>
    </WhiteBox>
  )
}


