import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import makeStyles from "@mui/styles/makeStyles";
import Chip from './../Chip'
import dayjs from "dayjs";

import { getAverageScore, round } from "utils";
import { useTheme } from '@mui/material/styles';
import TitleTooltip from "components/TitleTooltip";

const useStyles = makeStyles((theme) => ({
  card: {
    borderColor: theme.palette.neutral['clr-300'],
    borderStyle: "solid",
    borderWidth: "3px",
    width: '31%',
    minWidth: 320,
    borderRadius: theme.spacing(2),
    padding: theme.spacing(5,6),
    display: "flex",
    flexDirection: "column",
    gap:theme.spacing(5.5),
    position: "relative",
    transition: "all 300ms",
    textDecoration: "none",
    color: "unset",
    "&:hover": {
      borderColor : theme.palette.primary['clr-100'],
      boxShadow: '0px 0px 8px 0px #1961BF4D'
    },
  },
  bottomContent : {
    display : "flex",
    flexDirection : 'column',
    gap : theme.spacing(4)
  },
  tags:{
    display:'flex',
    flexDirection:'column',
    gap:theme.spacing(3)
  },
  tagName:{
    minWidth:'120px'
  },
  title: {
    textTransform: 'capitalize',
    marginTop: theme.spacing(1),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: '265px'
  },
}));

function TemplateAnalyticsCard(props) {
  const classes = useStyles();
  const theme = useTheme();

  const {
    name,
    expiresOn,
    _id,
    attemptedBy = [],
    assignedTo = [],
    type,
    communicationAverage,
    contentAverage,
  } = props;

  return (
    <Box  
      className={classes.card}
      component="a"
      href={`/assessments/${_id}/performance`}
      title="Click to view detailed analytics"
    >
        <Box display='flex' gap={2}>
          {expiresOn && dayjs(expiresOn).isAfter(new Date()) && (
            <Chip
              content="Live"
              size="small"
              bgColor={theme.palette.success['clr-100']}
              startIcon={
                          <img 
                            src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/live.svg" 
                            alt="live" 
                          />
                        }
              sx={{ 
                color: theme.palette.success['clr-700'], 
              }}
            />
            )}
            <Chip
              content={<Typography variant="body01-bold">{type}</Typography>}
              size="small"
              sx={{
                textTransform: 'capitalize',
                borderColor: theme.palette.primary.main,
                letterSpacing: 1,
              }}
            />
        </Box>
        <Box className={classes.bottomContent}>
          <TitleTooltip
            placement='bottom'
            component={name}
            disableHoverListener={name.length < 20}
            positionStyle={{ top: '-100px !important', left : '150px !important' , zIndex:2 }}
            href={`/assessments/${_id}/performance`}
            sx={{ maxWidth: '269px' }}
          >
            <Box sx={{ display: 'flex' }}>
              <Typography
                variant="h4-semiBold"
                sx={{
                  maxWidth: '269px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  color: 'neutral.clr-700',
                }}
              >
                {name}
              </Typography>
            </Box>
          </TitleTooltip>
          <Box className={classes.tags}>
            <Box mt={2} style={{ display: 'flex', alignItems: 'center' }}>
              <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/student_icon.svg" alt="student" />
              <Typography
                variant='body01-semiBold'
                mx={1}
                color='neutral.clr-900'
                sx={{minWidth:'97px'}}
              >
                {attemptedBy?.length || 0} Attempted
              </Typography>{" "}
              &nbsp;&nbsp;
              <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/attemptRateIcon.svg" alt="rate" />
              <Typography
                variant='body01-semiBold'
                color='neutral.clr-900'
                mx={1}
              sx={{minWidth:'155px'}}
              >
                {round(((attemptedBy?.length || 0) / assignedTo?.length) * 100)}
                %&nbsp;
                Attempt Rate
              
              </Typography>
            </Box>

            <Box mt={0} style={{ display: 'flex', alignItems: 'center' }}>
              <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/avgScoreIcon.svg" alt="score" />
              <Typography
                variant='body01-semiBold'
                color='neutral.clr-900'
                mx={1}
              >
                {getAverageScore(communicationAverage || 0, contentAverage || 0)}&nbsp;
                Avg. Score
              </Typography>
              &nbsp;&nbsp;
              <img src="https://languify-assets.s3.ap-south-1.amazonaws.com/images/batchIcon.svg" alt="batch" />
              <Typography
                mx={1}
                overflow={"hidden"}
                textOverflow="ellipsis"
                whiteSpace={"nowrap"}
                width="110px"
                variant='body01-semiBold'
                color='neutral.clr-900'
              >
                {props?.batch?.name || "NA"}
              </Typography>
            </Box>
        </Box>
      </Box>
      </Box>
  );
}

export default TemplateAnalyticsCard;
